import { SET_USER, SET_AUTHENTICATED, SET_UNAUTHENTICATED, LOADING_USER, SET_DIALOG_FOLDERS, SET_HISTORY_FOLDERS} from '../types'

const initialState = {
    authenticated: false,
    credentials: {},
    loading: false,
    dialogFolders: [],
    historyFolders: []
};

function userReducer(state = initialState, action){
    switch(action.type){
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true,
            };
        case SET_UNAUTHENTICATED:   
            return initialState;
        case SET_USER:
            return {
                ...state,
                authenticated: true,
                loading: false,
                ...action.payload
            };
        case LOADING_USER:
            return {
                ...state,
                loading: true
            };
        case SET_DIALOG_FOLDERS:
            return {
                ...state,
                loading: false,
                dialogFolders: action.payload.sort()
            };
        case SET_HISTORY_FOLDERS:
            return {
                ...state,
                loading: false,
                historyFolders: action.payload.sort()
            };
        default:
            return state;
    }
}

export default userReducer;