// User reducer types
export const LOADING_USER = "LOADING_USER";
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_DIALOG_FOLDERS = "SET_DIALOG_FOLDERS";
export const SET_HISTORY_FOLDERS = "SET_HISTORY_FOLDERS";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";

// UI reducer types
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const PAGE_CHANGED = "PAGE_CHANGED";
export const SET_ERRORS = "SET_ERRORS";

// Data reducer types
export const CLEAR_TEST_DETAILS = "CLEAR_TEST_DETAILS";
export const GET_TEST_DETAILS_COMPLETE = "GET_TEST_DETAILS_COMPLETE";
export const GET_TEST_DETAILS_STARTED = "GET_TEST_DETAILS_STARTED";
export const LOAD_TEST_HISTORY_COMPLETE = "LOAD_TEST_HISTORY_COMPLETE";
export const LOAD_TEST_HISTORY_STARTED = "LOAD_TEST_HISTORY_STARTED";
export const NAME_CHANGE_COMPLETE = "NAME_CHANGE_COMPLETE";
export const NAME_CHANGE_STARTED = "NAME_CHANGE_STARTED";
export const POST_NEW_TEST = "POST_NEW_TEST";
export const RESET_NEW_TESTS = "RESET_NEW_TESTS";
export const DELETE_TESTS_COMPLETE = "DELETE_TESTS_COMPLETE";
export const OTHER_DETAILS_CHANGE_STARTED = "OTHER_DETAILS_CHANGE_STARTED";
export const OTHER_DETAILS_CHANGE_COMPLETE = "OTHER_DETAILS_CHANGE_COMPLETE";
export const LOAD_PATIENT_TESTS_COMPLETE = "LOAD_PATIENT_TESTS_COMPLETE";
export const LOAD_PATIENT_TESTS_STARTED = "LOAD_PATIENT_TESTS_STARTED";
export const ADVANCED_TEST_SEARCH_COMPLETE = "ADVANCED_TEST_SEARCH_COMPLETE";
export const ADVANCED_TEST_SEARCH_STARTED = "ADVANCED_TEST_SEARCH_STARTED";
export const SET_SAMPLE_DATA_STARTED = "SET_SAMPLE_DATA_STARTED";
export const SET_SAMPLE_DATA_COMPLETE = "SET_SAMPLE_DATA_COMPLETE";
export const SAMPLE_SEARCH_STARTED = "SAMPLE_SEARCH_STARTED";
export const SAMPLE_SEARCH_COMPLETE = "SAMPLE_SEARCH_COMPLETE";
export const STOP_DATA_LOADING = "STOP_DATA_LOADING";
export const GET_SAMPLE_DETAILS_COMPLETE = "GET_SAMPLE_DETAILS_COMPLETE";
export const GET_SAMPLE_DETAILS_STARTED = "GET_SAMPLE_DETAILS_STARTED";
export const CLEAR_SAMPLE_DETAILS = "CLEAR_SAMPLE_DETAILS";


// Patient reducer types
export const LOADING_PATIENT = "LOADING_PATIENT";
export const LOADING_PATIENT_SUCCESS = "LOADING_PATIENT_SUCCESS";
export const LOADING_PATIENT_ERROR = "LOADING_PATIENT_ERROR";
export const RESET_PATIENT = "RESET_PATIENT";
export const REMOVE_PATIENT_ERRORS = "REMOVE_PATIENT_ERRORS";
export const GOT_PATIENT_ID = "GOT_PATIENT_ID";
export const GOT_PATIENT_LIST = "GOT_PATIENT_LIST";
export const LOADING_PATIENT_INFO_SUCCESS = "LOADING_PATIENT_INFO_SUCCESS";
export const UPDATE_PATIENT_LOCALLY = "UPDATE_PATIENT_LOCALLY";