import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import MuiListItem from "@material-ui/core/ListItem";

export const colors = {
    white: "#ffffff",
    black: "#000000",
    backgroundBlue: "#0b1025",
    foregroundBlue: "#1A2336",
    blueGrey: "#7785bf",
    listItemSelectedBlue: "#2D374D",
    listItemHoverBlue: "#252F44",
    listItemSelectedAndHoverBlue: "#364056",
    brightGreen: "#25ba20",
    brightRed: "#bf130a",
    brightBlue: "#1087FF",
    darkGrey: "#363636",
};

export const GreenTypography = withStyles({
    root: {
      color: "#3bc449",
    },
  })(Typography);

export const GreyTypography = withStyles({
    root: {
      color: "#c4c3c2",
    },
  })(Typography);

export const BlueTypography = withStyles({
    root: {
      color: colors.blueGrey,
    },
  })(Typography);

export const RedTypography = withStyles({
    root: {
      color: colors.brightRed,
    },
  })(Typography);

export const ListItem = withStyles({
    root: {
      "&$selected": {
        backgroundColor: colors.listItemSelectedBlue,
        color: "white",
        "& .MuiListItemIcon-root": {
          color: "white",
        },
      },
      "&$selected:hover": {
        backgroundColor: colors.listItemSelectedAndHoverBlue,
        color: "white",
        "& .MuiListItemIcon-root": {
          color: "white",
        },
      },
      "&:hover": {
        backgroundColor: colors.listItemHoverBlue,
        color: "white",
        "& .MuiListItemIcon-root": {
          color: "white",
        },
      },
    },
    selected: {},
  })(MuiListItem);
