import axios from "axios";
import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  SET_DIALOG_FOLDERS,
  SET_HISTORY_FOLDERS,
  RESET_NEW_TESTS,
} from "../types";
import { apiCall } from "../../util/apiCall";

export const loginUser = (userData, history) => async (dispatch) => {
  dispatch({ type: LOADING_UI });
  try {
    const res = await axios.post(apiCall("/login"), userData);
    setAuthorizationHeader(res.data.token);
    await dispatch(getUserData());
    history.push("/");
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    console.log(err.response.data);
    if (
      typeof err.response.data === "string" ||
      err.response.data instanceof String
    ) {
      err.response.data = { general: "Server error" };
    }
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const signupUser = (newUserData, history) => async (dispatch) => {
  dispatch({ type: LOADING_UI });
  try {
    const res = await axios.post(apiCall("/signup"), newUserData);
    history.push("/verifyEmail");
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    console.log(err);
    try{
      dispatch({
      type: SET_ERRORS,
      payload: err.response.data,
    });
    } catch(e) {
      dispatch({
        type: SET_ERRORS,
        payload: {key: "Error connecting to server"},
      });
    }
    
  }
};

export const getUserData = () => (dispatch) => {
  return new Promise(function (resolve, reject) {
    dispatch({ type: LOADING_USER });
    axios
      .get(apiCall("/user"))
      .then((res) => {
        dispatch({
          type: SET_USER,
          payload: res.data,
        });
        resolve();
      })
      .catch((err) => {
        console.error(err);
        resolve();
      });
  });
};

export const getUserHistoryFolders =
  (folder, dialog = false) =>
  (dispatch) => {
    //console.log("Sending request for subfolders of " + folder);
    const data = { folder: folder };
    dispatch({ type: LOADING_USER });
    axios
      .post(apiCall("/userHistoryFolders"), data)
      .then((res) => {
        if (dialog) {
          dispatch({
            type: SET_DIALOG_FOLDERS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: SET_HISTORY_FOLDERS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: SET_HISTORY_FOLDERS,
          payload: [],
        });
        if (err.response && err.response.status === 403) {
          dispatch({
            type: SET_ERRORS,
            payload:
              "Login session expired. Please refresh the page and sign in again.",
          });
        } else {
          try {
            dispatch({
              type: SET_ERRORS,
              payload: err.message,
            });
          } catch {
            dispatch({
              type: SET_ERRORS,
              payload: err.statusText,
            });
          }
        }
      });
  };

export const createNewHistoryFolder =
  (folderName, parentFolder) => (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch({ type: LOADING_USER });
      const data = {
        name: folderName,
        parentFolder: parentFolder,
      };

      axios
        .post(apiCall("/newHistoryFolder"), data)
        .then(async (res) => {
          console.log("New folder created");
          dispatch({
            type: CLEAR_ERRORS,
          });
          resolve();
        })
        .catch((err) => {
          console.log(err.response.data);
          try {
            dispatch({
              type: SET_ERRORS,
              payload: err.response.data,
            });
          } catch {
            dispatch({
              type: SET_ERRORS,
              payload: err.response.statusText,
            });
          }
          resolve();
        });
    });
  };

export const deleteHistoryFolder = (folderName, parentFolder) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    dispatch({ type: LOADING_USER });
    const data = {
      name: folderName,
      parentFolder: parentFolder,
    };
    axios
      .post(apiCall("/deleteHistoryFolder"), data)
      .then(async (res) => {
        console.log("Folder deleted");
        dispatch({
          type: CLEAR_ERRORS,
        });
        resolve();
      })
      .catch((err) => {
        console.log(err.response.data);
        try {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } catch {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.statusText,
          });
        }
        resolve();
      });
  });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("FBIdToken");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: SET_UNAUTHENTICATED });
  dispatch({ type: RESET_NEW_TESTS });
};

export const uploadProfileImage = (formData) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post(apiCall("/user/profileImage"), formData)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.error(err));
};

const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem("FBIdToken", FBIdToken);
  axios.defaults.headers.common["Authorization"] = FBIdToken;
};
