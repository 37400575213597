import React, { Component } from 'react'
import CircularProgress from "@material-ui/core/CircularProgress";

export class PageLoadingSpinner extends Component {
    render() {
        return (
            <CircularProgress
            style={{
                position: 'absolute',
                top: '50%',
                bottom: '50%',
                left: '50%',
                color: "primary",
                margin: '0'
            }}
            size={100}
            />
        )
    }
}

export default PageLoadingSpinner
