import { GOT_PATIENT_ID, LOADING_PATIENT, LOADING_PATIENT_ERROR, LOADING_PATIENT_SUCCESS, RESET_PATIENT, REMOVE_PATIENT_ERRORS, GOT_PATIENT_LIST, LOADING_PATIENT_INFO_SUCCESS, UPDATE_PATIENT_LOCALLY } from '../types'

const initialState = {
    loading: false,
    patientOtherDetails: {},
    name: "",
    email: "",
    number: "",
    patientID: "",
    errors: {},
    patientList: [],
    patientInfo: {}
};

function patientReducer(state = initialState, action){
    switch(action.type){
        case LOADING_PATIENT:
            return {
                ...state,
                loading: true,
                errors: {}
            }
        case LOADING_PATIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                errors: {},
                patientOtherDetails: action.payload.details,
                name: action.payload.name,
                email: action.payload.email,
                number: action.payload.number
            }
        case LOADING_PATIENT_ERROR:
            return {
                ...state,
                loading: false,
                errors: action.payload,
                patientOtherDetails: {},
            }
        case RESET_PATIENT:
            return initialState
        case GOT_PATIENT_ID:
            return {
                ...state,
                patientID: action.payload.id
            }
        case REMOVE_PATIENT_ERRORS:
            return {
                ...state,
                errors: {}
            }
        case GOT_PATIENT_LIST:
            return {
                ...state,
                loading: false,
                patientList: action.payload.data
            }
            case LOADING_PATIENT_INFO_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    patientInfo: action.payload.data
                }
            case UPDATE_PATIENT_LOCALLY:

                // Update patient in patientInfo
                if(action.payload.id === state.patientInfo.patientID) {
                    state.patientInfo.name = action.payload.data.name;
                    state.patientInfo.email = action.payload.data.email;
                    state.patientInfo.details = action.payload.data.details;
                }

                // Update patient in patientList

                for(let i = 0; i < state.patientList.length; i++){
                    if(state.patientList[i].patientID === action.payload.id){
                        state.patientInfo.name = action.payload.data.name;
                        state.patientInfo.email = action.payload.data.email;
                        state.patientInfo.details = action.payload.data.details;
                    }
                }

                return {
                    ...state,
                    loading: false
                }
        default:
            return state;
    }
}

export default patientReducer;