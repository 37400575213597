import './App.css';
import axios from 'axios';
import createTheme from '@material-ui/core/styles/createTheme';
import jwtDecode from 'jwt-decode';
import pavonisTheme from './util/theme';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

//Redux
import store from './redux/store';
import { logoutUser, getUserData } from './redux/actions/userActions';
import { Provider } from 'react-redux';
import { SET_AUTHENTICATED } from './redux/types';

//Components
import AuthRoute from './util/AuthRoute';
import NavBar from './components/NavBar';
import UnAuthRoute from './util/UnAuthRoute';
import PageLoadingSpinner from './components/PageLoadingSpinner';

// Pages
const newTest = lazy(() => import("./pages/newTest"));
const login = lazy(() => import('./pages/login'));
const signup = lazy(() => import("./pages/signup"));
const profile = lazy(() => import("./pages/profile"));
const home = lazy(() => import("./pages/home"));
const results = lazy(() => import("./pages/results"));
const verifyEmail = lazy(() => import("./pages/verifyEmail"));
const patients = lazy(() => import("./pages/patients"));
const patientInfo = lazy(() => import("./pages/patientInfo"));
const sampleSearch = lazy(() => import("./pages/sampleSearch"));
const validationReport = lazy(() => import("./pages/validationReport"));

const theme = createTheme(pavonisTheme);

const token = localStorage.FBIdToken;
try{
  if(token){
  // We are signed in
  const decodedToken = jwtDecode(token);
  if(decodedToken.exp * 1000 < Date.now()){
    // Token is expired
    store.dispatch(logoutUser());
    window.location.href = '/login';
  } else {
    store.dispatch( { type: SET_AUTHENTICATED } );
    axios.defaults.headers.common["Authorization"] = token;
    store.dispatch(getUserData());
  }
}
} catch {
  store.dispatch(logoutUser());
  window.location.href = '/login';
}


function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
      <Router>
        <Suspense fallback={<PageLoadingSpinner/>}>
      <NavBar/>
        <div className="container">
        <Switch>
          <UnAuthRoute exact path="/login" component={login}/>
          <UnAuthRoute exact path="/signup" component={signup}/>
          <UnAuthRoute exact path="/verifyEmail" component={verifyEmail}/>
          <AuthRoute exact path="/report" component={validationReport}/>
        </Switch>          
        </div>
        <div className="container-with-drawer">
        <Switch>
          <AuthRoute exact path="/" component={home}/>
          <AuthRoute exact path="/newTest" component={newTest}/>
          <AuthRoute exact path="/profile" component={profile}/>
          <AuthRoute exact path="/results" component={results}/>
          <AuthRoute exact path="/patients" component={patients}/>
          <AuthRoute exact path="/patient/info" component={patientInfo}/>
          <AuthRoute exact path="/sampleSearch" component={sampleSearch}/>
        </Switch>
        </div>
        </Suspense>
      </Router>
    </Provider>
    </MuiThemeProvider>
  );
}

export default App;
