import { colors } from "./colors";

const pavonisTheme = {
  palette: {
    primary: {
      main: colors.brightBlue,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.brightBlue,
      contrastText: colors.white,
    },
    action: {
      disabledBackground: colors.darkGrey,
      disabled: colors.black,
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiToolbar: {
      root: {
        textAlign: "center",
      },
    },
  },
  spreadIt: {
    center: {
      textAlign: "center",
    },
    dataTable: {
      textAlign: "center",
      'tbody tr': {
        cursor: 'pointer'
      },
    }, 
    image: {
      margin: "0px auto 0 auto",
      height: "100px",
      width: "auto",
    },
    pageTitle: {
      margin: "20px auto 20px auto",
    },
    whiteLink: {
      color: 'white',
      textDecoration: 'none'
    },
    textField: {
      margin: "5px auto 5px auto",
      width: "100%",
      //resize: 'vertical',
      appearance: "none",
      borderRadius: "6px",
      //padding: '0px 12px',
      //height: '32px',
      //minHeight: '32px',
      //fontSize: '14px',
      border: "1px solid #475369",
      backgroundColor: colors.backgroundBlue,
      color: "white",
      "&  .MuiFormHelperText-root.Mui-error": { //<--- here
        backgroundColor: colors.foregroundBlue,
        borderColor: 'transparent',
        margin: -1,
        paddingLeft: 10
      },
    },
    smallTextField: {
      margin: "5px auto 5px auto",
      appearance: "none",
      borderRadius: "6px",
      border: "1px solid #475369",
      backgroundColor: colors.backgroundBlue,
      color: "white",
      "&  .MuiFormHelperText-root.Mui-error": { //<--- here
        backgroundColor: colors.foregroundBlue,
        borderColor: 'transparent',
        margin: -1,
        paddingLeft: 10
      },
    },
    smallDisabledTextField: {
      margin: "5px auto 5px auto",
      appearance: "none",
      borderRadius: "6px",
      border: "1px solid #475369",
      backgroundColor: colors.backgroundBlue,
      color: "grey",
      "&  .MuiFormHelperText-root.Mui-error": { //<--- here
        backgroundColor: colors.foregroundBlue,
        borderColor: 'transparent',
        margin: -1,
        paddingLeft: 10
      },
    },
    button: {
      marginTop: 15,
      marginBottom: 15,
    },
    customError: {
      color: "red",
      fontSize: "0.8rem",
      marginTop: 10,
    },
    progress: {
      position: "relative",
      color: "primary",
      marginBottom: "0",
      display: "flex",
      justifyContent: "center",
    },
    smallProgress: {
      position: "relative",
      color: "white",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
    },
    invisibleSeparator: {
      border: "none",
      margin: 4,
    },
    visibleSeparator: {
      width: "100%",
      borderBottom: "1px solid rgba(0,0,0,0.1)",
      marginBottom: 20,
    },
    paper: {
      padding: 20,
      backgroundColor: colors.foregroundBlue,
      color: "white",
      width: "80%",
    },
    fade: {
      transition: "opacity 1s ease"
    },
    paperWithDrawer: {
      padding: 30,
      backgroundColor: colors.foregroundBlue,
      color: "white",
      width: "90%",
      margin: "auto auto",
      //margin: " 90 90 90 20",
      //flexGrow: 1,
      textAlign: "center",
    },
    darkPaper: {
      backgroundColor: colors.foregroundBlue,
      color: "white",
      textAlign: "center",
      width: "100%",
    },
    select: {
      color: 'white',
      "&:before": {
        borderColor: "white",
      },
      "&:after": {
        borderColor: "white",
      },
      "&:not(.Mui-disabled):hover::before": {
        borderColor: "white",
      },
    },
    
    icon: {
      fill: "white",
    },
    root: {
      color: "white",
    },
    profile: {
      "& .image-wrapper": {
        textAlign: "center",
        position: "relative",
        "& button": {
          position: "absolute",
          top: "80%",
          left: "70%",
        },
      },
      "& .profile-image": {
        width: 200,
        height: 200,
        objectFit: "cover",
        maxWidth: "100%",
        borderRadius: "50%",
      },
      "& .profile-details": {
        textAlign: "center",
        "& span, svg": {
          verticalAlign: "middle",
        },
        "& a": {
          color: colors.foregroundBlue,
        },
      },
      "& hr": {
        border: "none",
        margin: "0 0 10px 0",
      },
      "& svg.button": {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    buttons: {
      textAlign: "center",
      "& a": {
        margin: "20px 10px",
      },
    },
    drawer: {
      width: "drawerWidth",
      flexShrink: 0,
    },
    drawerPaper: {
      width: "15%",
      backgroundColor: colors.foregroundBlue,
      color: "white",
      verticalAlign: "center",
    },
    avatar: {
      height: 110,
      width: 100,
      flexShrink: 0,
      flexGrow: 0,
      marginTop: 20,
    },
    white: {
      color: "white",
      WebkitBoxShadow: "0 0 0 1000px #0b1025 inset",
      WebkitTextFillColor: "white",
    },
    grey: {
      color: "grey",
      WebkitBoxShadow: "0 0 0 1000px #0b1025 inset",
      WebkitTextFillColor: "white",
    },
    stretchButton: {
      width: "40%",
    },
    stretch: {
      flexGrow: 1,
    },
    selectFieldFormControl: {
      width: "100%",
      margin: "5px auto 5px auto",
      backgroundColor: colors.backgroundBlue,
    },
    selectFieldMenu: {
      backgroundColor: colors.backgroundBlue,
        color: colors.white,
        "& .MuiMenuItem-root.Mui-selected": {
          backgroundColor: colors.listItemSelectedBlue
        },
        "& .MuiMenuItem-root:hover": {
          backgroundColor: colors.listItemHoverBlue
        },
        "& .MuiMenuItem-root.Mui-selected:hover": {
          backgroundColor: colors.listItemSelectedAndHoverBlue
        },
    },
    selectFieldText: {
      border: "2px solid #475369",
      borderRadius: "6px",
      backgroundColor: colors.backgroundBlue,
      "& .MuiSvgIcon-root": {
        color: "white",
      },
    }
  },
};

export const darkDataTableTheme = (cursor='default') => {
  return {
  overrides: {
    MUIDataTableBodyCell: {
      root: {
        backgroundColor: colors.foregroundBlue,
        color: 'white',
        cursor: cursor,
        borderColor: colors.listItemSelectedBlue,
        //'&:hover': {
        //  backgroundColor: colors.listItemSelectedBlue
        //}
      },
    },
    MUIDataTableHeadCell: {
      root: {
        backgroundColor: colors.foregroundBlue,
        color: 'white',
        borderColor: colors.listItemSelectedBlue
      },
      sortActive: {
          backgroundColor: colors.foregroundBlue,
          color: 'white',
          borderColor: colors.listItemSelectedBlue
  
      },
    },
    MUIDataTableFilterList: {
      root: {
        backgroundColor: colors.foregroundBlue,
        color: 'white',
        borderColor: colors.listItemSelectedBlue
      },
    },
    MUIDataTableFilter: {
      root: {
        backgroundColor: colors.foregroundBlue,
        color: 'white',
        borderColor: colors.listItemSelectedBlue
      },
    },
    MUIDataTableSelectCell: {
      root: {
        backgroundColor: colors.foregroundBlue,
        color: 'white',
        borderColor: colors.listItemSelectedBlue
      },
      headerCell: {
        backgroundColor: colors.foregroundBlue,
        color: 'white',
        borderColor: colors.listItemSelectedBlue
      }
    },
    MuiIconButton: {
      colorPrimary: 'white'
    },
    MuiTablePagination: {
      root: {
        backgroundColor: colors.foregroundBlue,
        color: 'white',
        borderColor: colors.listItemSelectedBlue
      },
      selectIcon: {
        color: 'white'
      },
      actions: {
        color: 'white'
      }
    },
    MUIDataTableToolbar: {
      root: {
        backgroundColor: colors.foregroundBlue,
        color: 'white',
        borderColor: colors.listItemSelectedBlue
      },
      icon: {
        color: 'white'
      }
    },
    MuiButton: {
      root: {
        color: 'white'
      }
    },
    MUIDataTableFooter: {
      root: {
        backgroundColor: colors.foregroundBlue,
        color: 'white',
        borderColor: colors.listItemSelectedBlue
      }
    },
    MUIDataTableToolbarSelect: {
      root: {
        backgroundColor: colors.foregroundBlue,
        color: 'white',
        borderColor: colors.listItemSelectedBlue
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: colors.foregroundBlue,
        color: 'white',
        borderColor: colors.listItemSelectedBlue
      }
    },
    MuiGrid: {
      root: {
        backgroundColor: colors.foregroundBlue,
        color: 'white !important',
        borderColor: colors.listItemSelectedBlue
      }
    },
    MuiTypography: {
      root: {
        color: 'white !important',
      }
    },
    MuiFormLabel: {
      root: {
        color: 'white !important',
      }
    },
    MuiSelect: {
      root: {
        color: 'white !important',
      }
    },
    MuiSvgIcon: {
      root: {
        color: 'white'
      }
    },
    MuiPopover: {
      paper: {
        maxWidth: "16%",
      },
    },      
    
  }
};
};

export default pavonisTheme;
