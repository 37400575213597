import { SET_ERRORS, CLEAR_ERRORS, LOADING_UI, PAGE_CHANGED } from '../types'

const initialState = {
    loading: false,
    errors: {},
    currentPage: "/"
}

function uiReducer(state = initialState, action){
    switch(action.type){
        case SET_ERRORS:
            return {
                ...state,
                loading: false,
                errors: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                errors: {}
            }
        case LOADING_UI:
            return {
                ...state,
                loading: true,
            }
        case PAGE_CHANGED:
            return {
                ...state,
                currentPage: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default uiReducer;