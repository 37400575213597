import PropTypes from "prop-types";
import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom"

// MUI stuff
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography"
import { ListItem } from "../util/colors";

// Redux
import { connect } from "react-redux";
import { changePage } from "../redux/actions/uiActions";
import { logoutUser, uploadProfileImage } from "../redux/actions/userActions";

const styles = (theme) => ({
  ...theme.spreadIt,
});

class Navbar extends Component {
  handleLogout = () => {
    this.props.logoutUser();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.UI.currentPage === nextProps.UI.currentPage &&
      this.props.user.authenticated === nextProps.user.authenticated &&
      this.props.user.credentials.imageUrl === nextProps.user.credentials.imageUrl &&
      this.props.user.credentials.firstName === nextProps.user.credentials.firstName &&
      this.props.user.credentials.lastName === nextProps.user.credentials.lastName
      ){
        return false;
      } else {
        return true;
      }
  }
  

  render() {
    const {
      classes,
      UI: { currentPage },
      user: {
        credentials: { imageUrl, firstName, lastName },
        authenticated,
      },
    } = this.props;

    return (
      <div>
        <AppBar style={{ background: "#0B1025" }}>
          
            {authenticated && currentPage !== "/reports" &&
            <Toolbar className="nav-container"> 
              <Button variant="contained" color="primary" onClick={this.handleLogout} className={classes.button}>
                  Log Out
                </Button>
              <Typography color="inherit" style={{marginRight: '1rem'}}>
                Hello, {firstName} 
              </Typography>
            </Toolbar>
              }
            {!authenticated && 
            <Toolbar className="nav-container-center"> 
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
            <Button color="inherit" component={Link} to="/signup">
              Signup
            </Button>
          </Toolbar>
            }
            
              
        </AppBar>
        {authenticated && currentPage !== "/reports" && <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Divider />
          <center>
            <Avatar src={imageUrl} className={classes.avatar} component={Link} to="/profile"/>
            <p>
              {" "}
              {firstName} {lastName}
            </p>
          </center>
          <Divider />
          <List>
          <ListItem
              button
              key="home"
              component={Link} to="/"
              selected={currentPage === "/"}
            >
              <ListItemText primary="Home" />
            </ListItem>

            <ListItem
              button
              key="newTest"
              component={Link} to="/newTest"
              selected={currentPage === "/newTest"}
            >
              <ListItemText primary="New Test" />
            </ListItem>

            
              <ListItem button key="Test Results" component={Link} to="/results"
              selected={currentPage === "/results"}>
              <ListItemText primary="Test Results" />
            </ListItem>

            <ListItem button key="Antibody Validation" component={Link} to="/sampleSearch"
              selected={currentPage === "/sampleSearch"}>
              <ListItemText primary="Antibody Validation" />
            </ListItem>
            

            <ListItem button key="Patients" component={Link} to="/patients"
              selected={currentPage === "/patients"}>
              <ListItemText primary="Patients" />
            </ListItem>
            
            
            <ListItem button key="Profile" component={Link} to="/profile"
              selected={currentPage === "/profile"}>
              <ListItemText primary="Profile" />
            </ListItem>
          </List>
        </Drawer>}
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = { logoutUser, uploadProfileImage, changePage };

Navbar.propTypes = {
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  uploadProfileImage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(Navbar));
