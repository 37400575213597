import {
  POST_NEW_TEST,
  RESET_NEW_TESTS,
  NAME_CHANGE_STARTED,
  NAME_CHANGE_COMPLETE,
  LOAD_TEST_HISTORY_COMPLETE,
  LOAD_TEST_HISTORY_STARTED,
  GET_TEST_DETAILS_COMPLETE,
  GET_TEST_DETAILS_STARTED,
  CLEAR_TEST_DETAILS,
  DELETE_TESTS_COMPLETE,
  OTHER_DETAILS_CHANGE_STARTED,
  OTHER_DETAILS_CHANGE_COMPLETE,
  LOAD_PATIENT_TESTS_STARTED,
  LOAD_PATIENT_TESTS_COMPLETE,
  ADVANCED_TEST_SEARCH_COMPLETE,
  ADVANCED_TEST_SEARCH_STARTED,
  SET_SAMPLE_DATA_COMPLETE,
  SET_SAMPLE_DATA_STARTED,
  SAMPLE_SEARCH_COMPLETE,
  SAMPLE_SEARCH_STARTED,
  STOP_DATA_LOADING,
  GET_SAMPLE_DETAILS_COMPLETE,
  GET_SAMPLE_DETAILS_STARTED,
  CLEAR_SAMPLE_DETAILS
} from "../types";

const initializeState = {
  newTests: [],
  pastTests: [],
  patientTests: [],
  searchTests: [],
  pastSamples: [],
  testDetails: null,
  sampleDetails: null,
  loading: false,
  loadingTest: null,
};

function dataReducer(state = initializeState, action) {
  switch (action.type) {
    case POST_NEW_TEST:
      return {
        ...state,
        newTests: [action.payload, ...state.newTests],
      };
    case RESET_NEW_TESTS:
      return initializeState;
    case NAME_CHANGE_STARTED:
      return {
        ...state,
        loadingTest: action.payload.id,
      };
    case NAME_CHANGE_COMPLETE:
      if (!action.payload) {
        return {
          ...state,
          loadingTest: null,
        };
      }

      // Change name in newTests
      for (let i = 0; i < state.newTests.length; i++) {
        if (state.newTests[i].docID === action.payload.id) {
          state.newTests[i].name = action.payload.newName;
          break;
        }
      }

      // Change name in history
      for (let i = 0; i < state.pastTests.length; i++) {
        if (state.pastTests[i].testID === action.payload.id) {
          state.pastTests[i].name = action.payload.newName;
          break;
        }
      }

      // Change name in patientTests
      for (let i = 0; i < state.patientTests.length; i++) {
        if (state.patientTests[i].docID === action.payload.id) {
          state.patientTests[i].name = action.payload.newName;
          break;
        }
      }

      // Change name in test details
      if (state.testDetails && state.testDetails.docID === action.payload.id) {
        state.testDetails.name = action.payload.newName;
      }

      return {
        ...state,
        loadingTest: null,
      };
    case OTHER_DETAILS_CHANGE_STARTED:
      return {
        ...state,
        loadingTest: action.payload.id,
      };
    case OTHER_DETAILS_CHANGE_COMPLETE:
      if (!action.payload) {
        return {
          ...state,
          loadingTest: null,
        };
      }

      // Change other details in newTests
      for (let i = 0; i < state.newTests.length; i++) {
        if (state.newTests[i].docID === action.payload.id) {
          state.newTests[i].otherDetails = action.payload.newDetails;
          break;
        }
      }

      // Change other details in patientTests
      for (let i = 0; i < state.patientTests.length; i++) {
        if (state.patientTests[i].docID === action.payload.id) {
          state.patientTests[i].otherDetails = action.payload.newDetails;
          break;
        }
      }

      // Change other details in test details
      if (state.testDetails && state.testDetails.docID === action.payload.id) {
        state.testDetails.otherDetails = action.payload.newDetails;
      }
      return {
        ...state,
        loadingTest: null,
      };
    case LOAD_TEST_HISTORY_STARTED:
      return {
        ...state,
        pastTests: [],
        loading: true,
      };
    case LOAD_TEST_HISTORY_COMPLETE:
      return {
        ...state,
        loading: false,
        pastTests: action.payload,
      };
    case GET_TEST_DETAILS_STARTED:
      return {
        ...state,
        loadingTest: action.payload.id,
      };
    case GET_TEST_DETAILS_COMPLETE:
      return {
        ...state,
        loadingTest: null,
        testDetails: action.payload,
      };
    case CLEAR_TEST_DETAILS:
      return {
        ...state,
        loading: false,
        testDetails: null,
      };
    case DELETE_TESTS_COMPLETE:
      return {
        ...state,
        loading: false,
      };
    case LOAD_PATIENT_TESTS_STARTED:
      return {
        ...state,
        patientTests: [],
        loading: true,
      };
    case LOAD_PATIENT_TESTS_COMPLETE:
      return {
        ...state,
        loading: false,
        patientTests: action.payload,
      };
    case ADVANCED_TEST_SEARCH_STARTED:
      return {
        ...state,
        loading: true,
        loadingTest: true,
        searchTests: [],
      };
    case ADVANCED_TEST_SEARCH_COMPLETE:
      return {
        ...state,
        loading: false,
        loadingTest: null,
        searchTests: action.payload,
      };
    case SET_SAMPLE_DATA_STARTED:
      return {
        ...state,
        loading: true,
        loadingTest: action.payload.id,
      };
    case STOP_DATA_LOADING:
        return {
            ...state,
            loading: false,
            loadingTest: null
    }
    case SET_SAMPLE_DATA_COMPLETE:
      // Change colourData of sample in newTests
      for (let i = 0; i < state.newTests.length; i++) {
        if (state.newTests[i].docID === action.payload.id) {
          state.newTests[i].sampleDataComplete = true;
          state.newTests[i].colourData = action.payload.data;
          break;
        }
      }

      // Change colourData of sample in history
      for (let i = 0; i < state.pastTests.length; i++) {
        if (state.pastTests[i].testID === action.payload.id) {
          state.pastTests[i].sampleDataComplete = true;
          state.pastTests[i].colourData = action.payload.data;
          break;
        }
      }

      // Change colourData of sample in patientTests
      for (let i = 0; i < state.patientTests.length; i++) {
        if (state.patientTests[i].docID === action.payload.id) {
          state.patientTests[i].sampleDataComplete = true;
          state.patientTests[i].colourData = action.payload.data;
          break;
        }
      }

      // Change colourData of sample in test details
      if (state.testDetails && state.testDetails.docID === action.payload.id) {
        state.testDetails.sampleDataComplete = true;
        state.testDetails.colourData = action.payload.data;
      }

      return {
        ...state,
        loading: false,
        loadingTest: null,
      };
    case SAMPLE_SEARCH_STARTED:
      return {
        ...state,
        loading: true,
        pastSamples: [],
      };
    case SAMPLE_SEARCH_COMPLETE:
      return {
        ...state,
        loading: false,
        pastSamples: action.payload,
      };
      case GET_SAMPLE_DETAILS_STARTED:
        return {
          ...state,
          loadingSample: action.payload.id,
        };
      case GET_SAMPLE_DETAILS_COMPLETE:
        return {
          ...state,
          loadingSample: null,
          sampleDetails: action.payload,
        };
      case CLEAR_SAMPLE_DETAILS:
        return {
          ...state,
          loading: false,
          sampleDetails: null,
        };
    default:
      return state;
  }
}
export default dataReducer;
